footer {
  background-color: black;
  width: 100%;
  height: 1.1rem;
  text-align: center;
}

footer p {
  color: rgb(255, 254, 213);
  margin: 0;
  font-size: 1rem;
}
