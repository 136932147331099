.intro {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.intro h2 {
  font-size: 2rem;
  text-decoration: underline;
}
